import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getListFormation, trainingUpdate, trainingInsert } from '../api/api'
import { DataGrid } from '@mui/x-data-grid'
import {frFR} from '@mui/x-data-grid/locales'
import { Alert, Box, Button, FormLabel, Input, Snackbar } from '@mui/material'
import { Navigate, useNavigate } from 'react-router-dom'


const columns =[
    {field:'name',headerName:'Dénomination',flex:4,editable:true},
    {field:'abbreviation',headerName:'Abréviation',flex:1,editable:true},
    {field:'cycle',headerName:'Validitée',flex:1,editable:true},
    {field:'importance',headerName:'Ordre',flex:0.5,editable:true},
    {field:'active',headerName:'Active',flex:0.5,type:'boolean',editable:false,}
]


export const TrainingList=()=>{

    const [trainginList,setTrainingList]=useState([])
    const navigate=useNavigate()
    const [createFormation,setCreateFormation]=useState({new:false,data:{active:true}})
    const [snackbar, setSnackbar] = React.useState(null);

    const refData=useRef()


    const reloadTraining=()=>{
        getListFormation().then(training=>{
            setTrainingList(training.rows)
        })
        
    }
    useEffect(()=>{
        reloadTraining()
    },[])
    useEffect(()=>{
       
    },[trainginList])

   
    const proccessRowUpdate=useCallback(
        async(newRow)=>{
            const response=await  trainingUpdate(newRow);
            setSnackbar({children:'La formation à été sauvée',severity:'success'})
            return(newRow)
        },
        
    )
    const handleCloseSnackbar = () => setSnackbar(null);

    //On liste les formations
    return (
        
        <Box style={{width:'100%',height:'80vh',maxHeight:'90vh'}}>
            <Button onClick={()=>navigate("/workers")}>Retour</Button>
            <DataGrid
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                processRowUpdate={proccessRowUpdate}
                autoPageSize
                rows={trainginList}
                columns={columns}
                checkboxSelection
            ></DataGrid>
                 <Snackbar
          open={snackbar===null?false:true}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
            <Button style={{display:createFormation.new?'none':''}} onClick={()=>setCreateFormation({...createFormation,new:true})}>Nouveau</Button>
            {createFormation.new && (
                <Box style={{padding:10}}>
                <FormLabel>Création</FormLabel>
                    <Box>
                    <Input
                        placeholder='Nom'
                        style={{marginLeft:10,marginRight:10,width:500}}
                        value={createFormation.name}
                        onChange={e=>setCreateFormation({...createFormation,data:{...createFormation.data,name:e.target.value}})}
                    ></Input>
                    <Input
                        placeholder='Abréviation'
                        style={{marginLeft:10,marginRight:10}}
                        value={createFormation.abbreviation}
                        onChange={e=>setCreateFormation({...createFormation,data:{...createFormation.data,abbreviation:e.target.value}})}
                    ></Input>
                      <Input
                        placeholder='Cycle'
                        
                        style={{marginLeft:10,marginRight:10}}
                        type='number'
                        value={createFormation.cycle}
                        
                        onChange={e=>{
                            console.log(e)
                            if (!isNaN(e.target.value) && (e.target.value !== ""))
                                
                                { 
                                   
                                    setCreateFormation({...createFormation,data:{...createFormation.data,cycle:e.target.value}})
                                }
                            else{
                               
                               
                                setCreateFormation({...createFormation,data:{...createFormation.data,cycle:0}})}
                            }    
                        }
                        
                    ></Input>
                    <Button onClick={()=>{trainingInsert(createFormation.data).
                        then(e=>{reloadTraining();setCreateFormation({new:false,data:{active:true}})})
                        .catch()}} >Sauver</Button>
                    <Button onClick={()=>setCreateFormation({new:false,data:{active:true}})} >Annuler</Button>
                    </Box>
                </Box>
            )}
        </Box>
    ) 
    



}