import { Button } from "@mui/material";
import { PDFViewer, Document, Page, View, Text, Image, StyleSheet,Font } from "@react-pdf/renderer";
import { forEach } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { getDetailMulti } from "./api/api";

const ref=[{thersa:'DOS6-V1'}]
Font.register({
    family: 'Open Sans',
    fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
    ]
    });

  const styles = StyleSheet.create({
    title:{textAlign:'center',fontFamily:'Open Sans',width:'100%',alignSelf:'center',fontSize:12}
    ,
    text:{fontSize:10,fontFamily:'Open Sans',},
    footer:{fontSize:8,fontFamily:'Open Sans'},
    page: {
      backgroundColor: "#FFFFFF",
      padding: 10,
    },
    container: {
      flexDirection: 'row',
      flex:1,
      flexWrap: 'wrap',
      justifyContent: 'space-around',

    }
  });

export const AttestationPdf=({signataire,personnels,societe,competence,callBack})=>{
    const [company,setCompany]=useState({})
    const personnelss =personnels 
    const [competences,setComp]=useState(competence)
    useEffect(()=>{
       
        try{
            setCompany(JSON.parse(sessionStorage.getItem("company")))
        }
        catch{

        }
        
        //on va chercher les infos de la société. 
        //On genere les compétences : 
       if(personnels.length>0){
            //On récupere les formations de tout les utilisateurs nécessaire : 
            getDetailMulti(personnels.map(e=>e.id)).then(data=>{
                data.rows.forEach(cpt=>{
                    let indx= personnelss.findIndex(e=>e.id===cpt.idworker)

                    if(indx>-1){
                        
                        try{
                            personnelss[indx].competences.push(cpt)
                        }
                        catch{
                            personnelss[indx].competences=[]
                            personnelss[indx].competences.push(cpt)
                        }
                    }
                })
                setComp([...competence])
            })

       }
    },[personnels])
    
    


    let doc=()=>
        
        <Document>
            {personnelss.map(personnel=>(
                
                <Page key={personnel.id} size="A4" style={styles.page}>
                <View style={styles.container}>
                    <View style={{flexDirection: 'row',flexWrap: 'wrap',
        justifyContent: 'space-around',paddingTop:25}} fixed>
                    <View style={{backgroundColor:"#ECECEC",border:'solid',borderWidth:1,borderColor:'black',borderBottomWidth:0,minWidth:'90%',minHeight:10,padding:0}}>
                        <Text style={{...styles.title}}>Document Sécurité</Text>
                    </View>
                    <View style={{flexDirection:'row',maxWidth:'90%',alignItems:"stretch",alignItems:"stretch"}}>
                        <View key={"principal"} style={{width:'70%',border:'solid',borderWidth:1,borderColor:'black',justifyContent:'center',textAlign:'center'}}>
                            <Text style={{...styles.text}}>Attestation de compétence(s)</Text>
                        </View>
                        <View style={{width:'31%',marginLeft:-1}}>
                            <View style={{border:'solid',borderWidth:1,flexDirection:'row',justifyContent:'center'}}>
                                <View style={{flex:0.5,alignItems:'center'}}>
                                    <Text style={{...styles.text}}>Réf</Text>
                                </View>
                                <View style={{flex:0.5,borderLeft:'solid',borderLeftWidth:1,textAlign:'center'}}>
                                    <Text style={{...styles.text,borderLeft:'solid'}}>{company.ref}</Text>
                                </View>
                            </View>
                            <View style={{border:'solid',borderWidth:1,borderTopWidth:0,flexDirection:'row',textAlign:'center'}}>
                                <View style={{flex:0.5}}>
                                    <Text style={{...styles.text}}>Situation au </Text>
                                </View>
                                <View style={{flex:0.5,borderLeft:'solid',borderLeftWidth:1}}>
                                    <Text style={{...styles.text,borderLeft:'solid'}}>{moment().format("DD/MM/YYYY")}</Text>
                                </View>
                            </View>
                            <View style={{border:'solid',borderWidth:1,borderTopWidth:0,flexDirection:'row',textAlign:'center'}}>
                                <View style={{flex:0.5}}>
                                    <Text style={{...styles.text}}>Page</Text>
                                </View>
                                <View style={{flex:0.5,borderLeft:'solid',borderLeftWidth:1}}>
                                    <Text style={{...styles.text,borderLeft:'solid'}} render={({ pageNumber, totalPages })=>(`${pageNumber}/${totalPages}`)}/>
                                </View>
                            </View>
                        </View>
                        
                    </View>
                    </View>

                <View style={{minWidth:'90%',paddingTop:30}}>
                <Text style={{...styles.text}}>Par la présente, je sousigné {company?.mandatory},</Text>
                <View style={{flexDirection:'row'}}>
                        <Text style={{...styles.text}}>certifie que : </Text>
                        <Text style={{...styles.text,fontWeight:"bold"}}>{personnel.denomination}</Text>
                    </View>
                    <View style={{flexDirection:'row'}}>
                        <Text style={{...styles.text}}>travaillant sous contrat avec notre société : </Text>
                        <Text style={{...styles.text,fontWeight:'bold'}}>{company?.name}</Text>
                    </View>
                        <Text style={{...styles.text}}>a les compétences suivantes :</Text>
                </View>


                    <View>
                <View style={{paddingTop:30,minWidth:'90%',flexDirection:'row',}} fixed>
                        <View style={{flex:0.7,backgroundColor:'#ECECEC',borderWidth:1,borderRightWidth:0}}>
                            <Text style={{...styles.title}}>Comptétence(s)</Text>
                        </View>
                        <View style={{flex:0.3,backgroundColor:'#ECECEC',borderWidth:1}}>
                            <Text style={{...styles.title}}>Validitée</Text>
                        </View>
                    
                    </View>
                    {personnel?.competences?.map((e, index) => (
                            moment(e.dateValidity)>=moment()?
                                <View style={{paddingTop:0,minWidth:'90%',flexDirection:'row',}}>
                                    <View style={{ flex: 0.7, backgroundColor: 'white', borderWidth: 1,borderTopWidth:0, borderRightWidth: 0 }}>
                                    <Text
                                        style={{
                                        ...styles.text,
                                    
                                        padding: 5,
                                        textAlign: 'center',
                                        overflow: 'hidden', // Empêcher la cassure
                                        }}
                                    >
                                        {e.name}
                                    </Text>
                                    </View>

                                    {/* Colonne Validité */}
                                    <View style={{ flex: 0.3, backgroundColor: 'white', borderWidth: 1,borderTopWidth:0 }}>
                                    <Text
                                        style={{
                                        ...styles.text,
                                        padding: 5,
                                        textAlign: 'center',
                                        
                                        overflow: 'hidden', // Empêcher la cassure
                                        }}
                                    >
                                    {moment(e.dateValidity).format("DD/MM/YYYY")}
                                    </Text>
                                    </View>
                                    </View>
                                :
                                null

                            ))}
                </View>
            </View>
                
            <View style={{alignSelf:'flex-end',maxWidth:'100%',width:'95%',alignItems:'stretch'}}>
                    <Text style={{...styles.text}}>Les compétence(s) sont basées sur une formation initiale et la vérification pratique
                    Certifié sincère,</Text>
                    <View style={{height:100,width:150,alignSelf:'flex-end',right:30,marginTop:20,justifyContent:'center'}} >
                        <View style={{padding:"3px",borderWidth:1,alignItems:'center',backgroundColor:'white'}}>
                            <Text style={{...styles.footer}}>Signature de l'employeur</Text>
                        </View>
                        <View style={{borderWidth:1,borderTopWidth:0}}>
                            <Image src={company?.logosign}/>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            <View style={{flex:0.5,textAlign:'center',borderWidth:1,borderTopWidth:0}}>
                                <Text style={{...styles.footer}}>Mouscron le </Text>
                            </View>
                            <View style={{flex:0.5,textAlign:'center',borderWidth:1,borderTopWidth:0,borderLeftWidth:0}}>
                                <Text style={{...styles.footer}}>{moment().format("DD/MM/YYYY")}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            
                </Page>
           ))} 
        </Document>
        
    return(
        <>
        <Button style={{position:'absolute',top:35,left:20}} onClick={()=>callBack(false)}>Fermer l'apercu</Button>
        <PDFViewer  style={{ alignSelf:'center',width:'99.6%',maxWidth: "99.9%", maxHeight: "99vh",height:'97vh' }}>{doc()}</PDFViewer>
        </>
    )
}



