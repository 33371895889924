export function dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
      
    }
    if(property.includes(".")){
      property=property.split(".")
     // console.log(property)
    }
    return function (a,b) {
   //   console.log(a,b)
      var result
      if(typeof(property)==="object"){
        try{
        result= (a[property[0]][property[1]] < b[property[0]][property[1]]) ? -1 : (a[property[0]][property[1]] > b[property[0]][property[1]]) ? 1 : 0;
        }
        catch{
          result=-1
        }
      }
      else{
       result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
     // return result * sortOrder;
      }
      return result * sortOrder;
    }
  }
  export function dynamicSortMultiple() {
    var props = arguments;
    return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;
        while(result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(obj1, obj2);
            i++;
        }
        return result;
    }
}
