import React, { useEffect, useState } from "react";
import QRCode2 from 'qrcode';
import { PDFViewer, Document, Page, View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import { Box, Button, Typography } from "@mui/material";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { saveAs } from "file-saver";
import { fetchQr } from "./api/api";


export const PdfQrCode = ({ list = [], callBack=()=>{} }) => {
  const [wait,setWait]=useState(true)
  const[doc,setDoc]=useState(null)
  const[file,setFile]=useState(null)
  const[_error,setError]=useState(null)
  useEffect(()=>{
    fetchQr(list).then(data=>{setFile(data);setWait(false)}).catch(e=>setError(e))
  },[])
return(
  <Box style={{minHeight:'98vh',width:'100%',alignItem:'center',justifyContent:'center',display:'flex'}}>
        
            <Box style={{backgroundColor:'white',alignSelf:'center',justifyContent:'center',minWidth:'50%'}}>
            <Box style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItem:'center'}}>
            {wait?
            <>
                <Dots style={{alignSelf:'center'}}/>
                <Typography style={{alignSelf:'center'}}> Génération en cours ...   </Typography>
          
                </>
            :
           <>
          
               <Typography style={{alignSelf:'center'}}>Le document à été envoyé par mail </Typography>
                <Button onClick={()=>{saveAs( new Blob( [file]),'qrcode.zip');callBack(false)}}>Télécharger le fichier</Button>
                </>

           
                }
                 <Button onClick={()=>callBack(false)}>Fermer</Button>
             </Box>
           
      
            </Box>
     </Box>
    )

}

export default PdfQrCode;
