import axios from "axios";
import qs from 'qs'
import md5 from 'react-native-md5'
const forTest ="http://192.168.0.181:3000" 

const axiosWithHeaders=axios.create({
    baseURL:`${window.location.protocol}//${window.location.hostname}/worker`,
    headers:{'customauthentificator':'e3ce79ff-b5f3-4778-9f21-6f075449ac41','userid': sessionStorage.getItem('idUser')}

})

axiosWithHeaders.interceptors.request.use(config => {
    const userId = sessionStorage.getItem('idUser');
    if (userId) {
        config.headers['userid'] = userId; // Met à jour l'en-tête userid dynamiquement
    }
    return config;
}, error => {
    return Promise.reject(error);
});
export const changeAuth=async(data)=>{
    const letData={mail:sessionStorage.getItem('idUser'),hash:md5.hex_md5(data.new),oldHash:md5.hex_md5(data.old)}
    try{
        let {res}=await axiosWithHeaders.put("/tools/authentification",letData)
        return(res)
    }
    catch(e){
        
        throw(e)
    }
}
/// Gestion des formations 
export const verifyAuth=async(auth)=>{
 
    try{
        let{data}=await axiosWithHeaders("/tools/authentification",{params:{hash:auth.password,mail:auth.mail}})
       
        return(data)
        
    }
    catch(e){
        throw(e)
    }
}
export const trainingInsert=async(_data)=>{
    try{
        let {data}=await axiosWithHeaders.post("/formations",_data)
        return(data)
    }
    catch(e){
        throw(e)
    }
}
export const workerAdd=async(_data)=>{
    try{
        let {data}=await axiosWithHeaders.post("/personnel",_data)
        return(data)
    }
    catch(e){
        throw(e)
    }
}
export const workerUpdate=async(_data)=>{
    try{
        let {data}=await axiosWithHeaders.put("/personnel",_data)
        return(data)
    }
    catch(e){
        console.log(e)
        throw(e)
    }
}
export const trainingUpdate=async(_data)=>{
    try{
        let {data}=await axiosWithHeaders.put("/formations",_data)
        return(data)
    }
    catch(e){
        throw(e)
    }
}
export const getHistory = async(params)=>{
 
   try{ 
        let {data}=await axiosWithHeaders.get("/tools/history",{params})
        
        return(data)
   }
   catch(e){
    throw(e)
   }
}

export const trainingDelete=async(_data)=>{
    try{
        await axiosWithHeaders.delete("/formations",_data)
        return(true)

    }
    catch(e){
        throw(e)
    }
}

//await axios.get(`${window.location.protocol}//${window.location.hostname}/worker/workers/${id}`);
export const updateCompetence=async(_data)=>{
    try{
        let {data}=await axiosWithHeaders.put("/formations/competences",_data)
        return(data)
    }
    catch(e){
        throw e
    }
}
export const deleteCompetence=async(rowId)=>{
    try{
        let {data}=await axiosWithHeaders.delete('/formations/competences/'+rowId)
        return(data)
    }
    catch(e){
        throw e
    }
}
export const getDetail=async(uid,isAdmin=false)=>{
    try{
        let {data}=await axiosWithHeaders.get(`/personnel/list/${uid}`,{params:{fromApp:isAdmin}})
     
        return(data)
    }
    catch(e){
        throw(e)
    }
}
export const getDetailMulti=async(ids)=>{
    
    try{
        let {data}=await axiosWithHeaders.get('/personnel/list/'+ids)
        
      
        return(data)
    }
    catch(e){
        console.log(e)
    }
}


export const fetchPdf=async(type,param,mail)=>{
    try{
       
        const document = await axiosWithHeaders.get("/tools/sendAttestation",{responseType:'blob',params:{ids:param,type:type,mail:mail}})
        if(mail){
           
            return(document.data)
        }
        else{
        return(URL.createObjectURL(document.data))//
        }
    }
    catch (e){
        throw e
    }
}

export const fetchQr=async(ids)=>{
    try{
       
        const document = await axiosWithHeaders.get("/tools/generateqr",{responseType:'blob',params:{ids:ids}})

       
           
            return(document.data)
        
       
    }
    catch (e){
        throw e
    }
}
export const getListPersonnel=async (showOut=false)=>{
    try{
        try{
        
        var idCompany = JSON.parse(sessionStorage.getItem("company")).id
       
        }
        catch{}
    let {data}=await axiosWithHeaders.get(`/personnel/${idCompany}`,{params:{showout:showOut}})
        return(data)
}
    catch(e){
        throw (e)
    }
}

export const getCompany=async(id=null)=>{
    try{
        var data 
        id===null?
         {data}=await axiosWithHeaders.get("/company")
        :
         {data}=await axiosWithHeaders.get("/company/"+id)

        return(data)
    }
    catch (e){
        throw e
    }
}

export const getListFormation=async ()=>{
    try{
    let {data}=await axiosWithHeaders.get("/formations")
        return(data)
}
    catch(e){
        throw (e)
    }
}

export const apiCreateFormation=async(body)=>{

    try{
        let {data}=await axiosWithHeaders.post("/formations/competences",body)

        return(data)
    }
    catch(e){
        console.log(e)
        throw(e)
    }
}

