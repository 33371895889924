import { useEffect, useState } from "react"
import { fetchPdf } from "./api/api"
import { PdfJs, Position, Viewer,Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar'; // Plugin pour ajouter un bouton
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import { SidebarTab } from '@react-pdf-viewer/default-layout';
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { saveAs } from "file-saver";

export const Attestation=({personnels,callBack})=>{
  
    const [snackBar,setSnackbar]=useState(null)
    const [wait,setWait]=useState(true)
    const[doc,setDoc]=useState(null)
    const[file,setFile]=useState(null)
    const defaultpl=defaultLayoutPlugin(
      
    )
    const toolbar = toolbarPlugin();
    const { Toolbar } = toolbar;
    useEffect(()=>{
       
        fetchPdf("attestation",personnels.map(e=>e.id),true).then(url=>{
            //window.open(URL.createObjectURL(url))
           
            setFile(url)
           setWait(false)

        })

    },[])

    const handleSendMail=()=>{
 
        fetchPdf("attestation",personnels.map(e=>e.id),true).then(e=>
            setSnackbar({children:`L'émail à été envoyé`,severity:'success'}
        )).
        catch(e=> setSnackbar({children:'une erreur à été détectée',severity:'error'}))
    }


const handleCloseSnackbar = () => setSnackbar(null);

   

    return(
    <Box style={{minHeight:'98vh',width:'100%',alignItem:'center',justifyContent:'center',display:'flex'}}>
        <Snackbar open={snackBar?true:false} autoHideDuration={6000} anchorOrigin={{ vertical:'top', horizontal:'center' }} children={
            <Alert {...snackBar} onClose={handleCloseSnackbar}/>
        }/>
            <Box style={{backgroundColor:'white',alignSelf:'center',justifyContent:'center',minWidth:'50%'}}>
            <Box style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItem:'center'}}>
            {wait?
            <>
                <Dots style={{alignSelf:'center'}}/>
                <Typography style={{alignSelf:'center'}}> Génération en cours ...   </Typography>
          
                </>
            :
           <>
          
               <Typography style={{alignSelf:'center'}}>Le document à été envoyé par mail </Typography>
                <Button onClick={()=>{saveAs( new Blob( [file]),'attestations.zip');callBack(false)}}>Télécharger le fichier</Button>
                </>

           
                }
                 <Button onClick={()=>callBack(false)}>Fermer</Button>
             </Box>
           
      
            </Box>
     </Box>
    )

}