import React, { useEffect, useState } from "react";
import { getListPersonnel,getListFormation, apiCreateFormation } from "./api/api";
import { Autocomplete, Button, Card, Chip, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { isMobile } from "react-device-detect";

export const NewCompetence=()=>{
  
    const [personnels,setPersonnels]=useState([])
    const [selectedPersonnels,setSelectedPersonnel]=useState([])
    const [selectedFormation,setSelectedFormation]=useState({})
    const [formations,setFormations]=useState([])
    const [dateFormation,setDateFormation]=useState(moment())
    const [dateRecyclage,setDateRecyclage]=useState(moment().add(100,"year"))
    const [company,setCompany]=useState({})
    const location = useLocation()

    const navigate=useNavigate()
 
    const createFormation=()=>{
       
        if(selectedPersonnels.length>0 && selectedFormation?.id){
            apiCreateFormation({personnel:selectedPersonnels,formation:selectedFormation.id,dateFormation:dateFormation,endDate:dateRecyclage}).then(data=>{
                setDateFormation(moment())
                setSelectedFormation({})
                setSelectedPersonnel([])
                navigate("/workers")
                }
            ).catch(e=>{
              alert("Impossible d'ajouter les formations")  
            })
        }
    }
    
    

    useEffect(()=>{
       
        try{
            setCompany(JSON.parse(sessionStorage.getItem("company")))
        }
        catch{

        }
        getListPersonnel().then(data=>{setPersonnels(data.rows)}).catch(e=>alert("Erreur"))
        getListFormation().then(data=>{setFormations(data.rows)})
    },[])
    useEffect(()=>{
      
        if(personnels.length>0){
          var selected=[]
          location.state?.selected.forEach(e=>{
            selected.push(e)
          })  
         
          setSelectedPersonnel(selected)
        }
    },[personnels])
    useEffect(()=>{
       
        var _toAdd=1200
        if(selectedFormation?.cycle)
        {   
            _toAdd = selectedFormation?.cycle===0?1200:selectedFormation.cycle
           
          

           
        }
        let _date = moment(dateFormation)
        setDateRecyclage(_date.add(_toAdd,"month"))
    },[dateFormation,selectedFormation])
    if(sessionStorage.getItem("adminLogged")!=1){
        return(
            <div style={{display:'flex',flexDirection:'column',minHeight:'100vh',justifyContent:'center',alignItems:'center'}}>
           
            <h2>Vous n'êtes pas authentifier</h2>
            </div>
        )
    }
    return(
        <div style={{display:'flex',flexDirection:'column',minHeight:'100vh',justifyContent:'center',alignItems:'center'}}>
           <img style={{height:150}} src={company?.logo}/>
            <h2>Ajout d'une formation : </h2>
           
        
            {personnels.length>0&&(
            <Autocomplete
                disablePortal
                multiple
                onChange={(event,value)=>{setSelectedPersonnel(value)}}
                options={personnels}
                value={selectedPersonnels}
                getOptionLabel={(option) => option.denomination} // Specify how to get the label from the option object
                sx={{ maxWidth:'80%',minWidth:isMobile?300:'75%' }}
                renderInput={(params) => <TextField {...params} label="Personnel" />}
            />

            
            
        )}
            
            <div style={{display:'flex',minWidth:'75%',paddingTop:isMobile?0:'5px',flexDirection:isMobile?"column":"row",justifyContent:isMobile?'center':'space-between',flexWrap:'wrap',alignItems:'center',alignContent:'center'}}>
            {formations.length>0&&(
            <Autocomplete
                disablePortal
                freeSolo
                onChange={(event,value)=>{setSelectedFormation(value)}}
                options={formations}
              
                getOptionLabel={(option) => option.name} // Specify how to get the label from the option object
                sx={{ maxWidth:'80%',minWidth:300,paddingTop:"5px",flexGrow:2}}
                renderInput={(params) => <TextField {...params} label="Formation" />}
            />

           
            
        )}
        <DatePicker onChange={e=>setDateFormation(e)} value={dateFormation} sx={{marginLeft:isMobile?0:'10px',minWidth:300,paddingTop:"5px",}} />
        <DatePicker value={dateRecyclage} disabled sx={{minWidth:300,paddingTop:"5px",marginLeft:isMobile?0:'10px'}} />
        </div>
        <div style={{display:'flex',flexDirection:'row-reverse',justifyContent:'space-between',minWidth:'75%',paddingTop:20}}>
        <Button onClick={()=>createFormation()}>Ajouter les formations</Button>
        <Button onClick={()=>{navigate("/workers")}} >Annuler</Button>
        </div>
        </div>
    )

}