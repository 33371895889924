import { Box, Button, Checkbox, Divider, FormLabel } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import {frFR} from '@mui/x-data-grid/locales'
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { useState } from "react"
import { getHistory } from "./api/api"
import { useNavigate } from "react-router-dom"

const cols =[
    {field:'denomination',headerName:'Dénomination',flex:2,editable:false},
    {field:'date',headerName:'Date',renderCell:(field)=>(moment(field.value).format("DD/MM/YYYY hh:mm:ss")),flex:2,editable:false},
    {field:'company',headerName:'Société',flex:1,editable:false},
]


export const History=()=>{
    
    const [params,setParams]=useState({from:moment().startOf("day"),to:moment().endOf("day"),allCompany:false})
    const [results,setResults]=useState({data:[],count:0})
    const navigate=useNavigate()
    const searchHistory=()=>{
        let _params={...params}
        if(_params.allCompany===false){
            _params.idcompany=JSON.parse(sessionStorage.getItem("company")).id
        }
        delete _params.allCompany
        getHistory(_params).then(data=>{setResults({data:data,count:data.length})}).catch(e=>setResults({data:[],count:0}))
    }

    return(
        <Box style={{display:'flex',width:'100%',height:'100vh',alignItems:'center',flexDirection:'column'}}>
           
            <Box style={{display:'flex',width:'80%',maxHeight:'15vh',flexDirection:'row',justifyContent:'space-between',padding:20,alignItems:'center'}}>
            <Button onClick={()=>navigate("/")} >Retour</Button>
                <Box style={{display:'flex',alignItems:'center'}}>
                    <FormLabel style={{paddingRight:10}}>Début</FormLabel>
                    <DatePicker onChange={e=>setParams({...params,from:e})} value={params?.to||moment()}></DatePicker>
                </Box>
                <Box style={{display:'flex',alignItems:'center'}}>
                    <FormLabel style={{paddingRight:10}}>Fin</FormLabel>
                    <DatePicker onChange={e=>setParams({...params,to:e})} value={params?.to||moment()}></DatePicker>
                </Box>
                <Box style={{display:'flex',alignItems:'center'}}>
                    <FormLabel style={{paddingRight:10}}>Afficher toutes les sociétés</FormLabel>
                    <Checkbox onChange={(e)=>setParams({...params,allCompany:e.target.checked})}></Checkbox>
                </Box>
                <Button onClick={()=>searchHistory()} >Rechercher</Button>
            </Box>
           
            <Divider orientation="horizontal" flexItem/>
            <Box style={{display:'flex',alignItems:'center',padding:20,width:'90%',maxHeight:'100vh',height:'100vh',justifyContent:'center'}}>
                {results.count>0?

                    <DataGrid
                        columns={cols}
                        rows={results.data}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        autoPageSize  
                        pagination
                        disableRowSelectionOnClick
                    />
                    :
                    <FormLabel>Aucun résultat</FormLabel>
                    
                }
            </Box>
        </Box>
    )

}