import { Box, Button, Input, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { changeAuth } from "../api/api"


export const ResetPassword=({callBack})=>{


    const[mail,setMail]=useState(sessionStorage.getItem("idUser"))
    const[dataSend,setData]=useState({old:null,new:null,verification:null,verificationError:false})

     useEffect(()=>{
          if(dataSend.new===dataSend.verification || dataSend.verification==="" ){
            setData({...dataSend,verificationError:false})
            }
            else {
                setData({...dataSend,verificationError:true})
            }
     },
     [dataSend.old,dataSend.verification]
     )
    const validateChange=()=>{

        changeAuth(dataSend).then(data=>{callBack(data)}).catch(e=>callBack({error:true,message:e}))
    }

     return(
        <Box style={{display:'flex',minHeight:'100vh',justifyContent:'center',alignItem:'center',flexDirection:'column'}}>
            
            <Box style={{display:'flex',flexDirection:'column',width:'50%',alignSelf:'center',backgroundColor:'white',padding:10,borderRadius:10}}>
            <Typography style={{alignSelf:'center'}}>Changement de mot de passe pour {mail}</Typography>
            <Input placeholder="Ancien mot de passe" type='password' onChange={(e)=>{setData({...dataSend,old:e.target.value})}} />
            <Input placeholder="Nouveau mot de passe" type='password' onChange={(e)=>{setData({...dataSend,new:e.target.value})}} />
            <Input error={dataSend.verificationError} placeholder="Verification" type='password' onChange={(e)=>{setData({...dataSend,verification:e.target.value})}} />
            <Box style={{justifyContent:'space-between',display:'flex'}}>
                <Button  onClick={()=>callBack(false)}>Annuler</Button>
                <Button disabled={dataSend.verificationError && dataSend.verification!=''} onClick={()=>validateChange()}>Valider</Button>
            </Box>
            </Box>
        </Box>
     )
}   

